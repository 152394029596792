<template>
  <div class="thaana el-input el-input--mini">
    <el-input
      size="mini"
      :disabled="disabled"
      @keypress.native.prevent="get_dhivehi($event)"
      v-model="runningText"
      ref="dhivehi_name"
      id="dhivehi_name"
      class="thaana"
    ></el-input>
  </div>
</template>
<script>
export default {
  watch: {
    value: function () {
      this.selectedValue = this.value;
      this.runningText = this.value;
    },
  },
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {},
  data() {
    return {
      selectedvalue: "",
      runningText: "",
    };
  },
  methods: {
    get_dhivehi(e) {
      var keyMap = {
        q: "ް",
        w: "އ",
        e: "ެ",
        r: "ރ",
        t: "ތ",
        y: "ޔ",
        u: "ު",
        i: "ި",
        o: "ޮ",
        p: "ޕ",
        a: "ަ",
        s: "ސ",
        d: "ދ",
        f: "ފ",
        g: "ގ",
        h: "ހ",
        j: "ޖ",
        k: "ކ",
        l: "ލ",
        z: "ޒ",
        x: "×",
        c: "ޗ",
        v: "ވ",
        b: "ބ",
        n: "ނ",
        m: "މ",
        Q: "ޤ",
        W: "ޢ",
        E: "ޭ",
        R: "ޜ",
        T: "ޓ",
        Y: "ޠ",
        U: "ޫ",
        I: "ީ",
        O: "ޯ",
        P: "÷",
        A: "ާ",
        S: "ށ",
        D: "ޑ",
        F: "ﷲ",
        G: "ޣ",
        H: "ޙ",
        J: "ޛ",
        K: "ޚ",
        L: "ޅ",
        Z: "ޡ",
        X: "ޘ",
        C: "ޝ",
        V: "ޥ",
        B: "ޞ",
        N: "ޏ",
        M: "ޟ",
        ",": "،",
        ";": "؛",
        "?": "؟",
        "<": ">",
        ">": "<",
        "[": "]",
        "]": "[",
        "(": ")",
        ")": "(",
        "{": "}",
        "}": "{",
      };
      var d_unicode = keyMap[e.key] || e.key;
      this.runningText += d_unicode;
      this.selectedValue = this.runningText;
      this.$emit("input", this.selectedValue);
    },
  },
  name: "DhivehiField",
};
</script>
